<template>
    <div class="doc-container">
        <CTabs :active-tab.sync="activeTab">
            <CTab>
                <template slot="title">Masuk 
                    <CBadge color="info" shape="pill" class="ml-2" id="badgeIn">0</CBadge>
                </template>
                <div class="mt-20">
                    <archive-in :archive-state-prop="true" />
                </div>
            </CTab>
            <CTab>
                <template slot="title">Keluar
                    <CBadge color="info" shape="pill" class="ml-2" id="badgeOut">0</CBadge>
                </template>
                <div class="mt-20">
                    <archive-out :archive-state-prop="true" />
                </div>
            </CTab>
            <CTab>
                <template slot="title">Nota Dinas
                    <CBadge color="info" shape="pill" class="ml-2" id="badgeNotaDinas">0</CBadge>
                </template>
                <div class="mt-20">
                    <archive-nota :archive-state-prop="true" />
                </div>
            </CTab>
            <CTab>
                <template slot="title">Arsip
                    <CBadge color="info" shape="pill" class="ml-2" id="badgeArchive">0</CBadge>
                </template>
                <div class="mt-20">
                    <archive-old :archive-state-prop="true" />
                </div>
            </CTab>
        </CTabs>
    </div>
</template>

<script>
import In from '@/views/document/In.vue'
import Out from '@/views/document/Out.vue'
import Archive from '@/views/document/All.vue'
import Nota from '@/views/document/Nota.vue'
export default {
    components: {
        'archive-in': In,
        'archive-out': Out,
        'archive-old': Archive,
        'archive-nota': Nota
    },
    data () {
        return {
            activeTab: 0
        }
    }
}
</script>