<template>
<div class="dashboard-container">
	<v-app>
		<!-- Header -->
		<div class="header-container">
			<!-- Create Document Action -->
			<div class="flex-grow-0 p-0">
				<CButton @click="modalArchiveDocument.isShow = true" color="danger" size="sm">
					<CIcon name="cil-plus"/> Tambah Arsip Baru
				</CButton>
			</div>
			<!-- Advance Search -->
			<div class="flex-grow-1 mt-2 ml-2">
				<advance-search
					ref="advanceSearch"
					:doc-type-prop="'Arsip'"
					:category-options-prop="categoryOptions"
					:archive-state-prop="archiveStateProp"
					@apply-advance-filter="applyAdvanceFilter">
				</advance-search>
			</div>
		</div>
		<hr class="bottom-devider"/>
		<!-- Body -->
		<div class="body-container">
			<div class="p-2 position-relative mb-10" style="min-height:150px">
				<CRow>
					<CCol v-for="(item, index) in document.data" :key="index" col="4">
						<document-card
							v-bind:item-prop="item"
							v-on:show-disposition-form="showDispositionForm">
						</document-card>
					</CCol>
				</CRow>
			</div>
			<div class="content-center">
				<div class="p-5" v-if="renderData === false && pagination.ending === true">
					<p class="text-center">Anda mencapai batas akhir dokumen.</p>
				</div>
				<div class="p-5" v-else-if="renderData === false && pagination.noData === true">
					<p class="text-center">Tidak ada data ditemukan.</p>
				</div>
				<div class="p-5" v-else-if="renderData === false && pagination.ending === false">
					<a @click="loadMore" class="btn btn-danger btn-sm text-white text-center">Muat lebih banyak.</a>
				</div>
				<div v-else class="loader content-center p-2">
					<v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
				</div>
			</div>
		</div>

		<!-- Archive Document Popup Modal -->
		<archive-document-form
			:popup-modal-prop="modalArchiveDocument"
			:category-options-prop="categoryOptions"
			@close-popup-modal="closeArchiveDocumentModal"/>
	</v-app>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex'

import AdvanceSearch from '@/components/document/AdvanceSearch.vue'
import ArchiveDocument from '@/components/document/ArchiveDocument.vue'
import DocumentCard from '@/components/document/DocumentCard.vue'
export default {
	names: 'All',
	props: {
		archiveStateProp: {
			type: Boolean,
			default: false
		}
	},
	components: {
		'advance-search': AdvanceSearch,
		'archive-document-form': ArchiveDocument,
        'document-card': DocumentCard
    },
	data () {
		const document = {
			data: [],
			filters: {
				type: 'Arsip',
				isArchive: null,
				isPublish: null,
				isDone: null
			},
			sorts: null
		}
		const pagination = {
			itemsPerPage: 9,
			activePage: 1,
			itemLoaded: 0,
			ending: false,
			noData: false
		}
		const categoryOptions = []
		const modalArchiveDocument = {
			isShow: false
		}
		return {
			renderData: true,
			document,
			pagination,
			categoryOptions,
			modalArchiveDocument
		}
	},
	computed: {
		...mapState('auth', {
			loggedUserId: 'user_id',
			loggedUserPosition: 'position'
		})
	},
	mounted () {
		// Disposition
		this.getCategoryOptions()
		this.getDocumentList()
			.then( data => {
				data.items.forEach((item) => {
					this.document.data.push(item)
				})
				this.renderData = false
				if(this.pagination.itemLoaded === 0) this.pagination.noData = true
				if(this.pagination.itemLoaded >= data.total) this.pagination.ending = true

				if(this.archiveStateProp) {
					var elementId = 'badgeArchive'
					if(data.total == 0) {
						document.getElementById(elementId).classList.add('hide')
						document.getElementById(elementId).innerHTML=''
					} else {
						document.getElementById(elementId).classList.remove('hide')
						document.getElementById(elementId).innerHTML = (data.total > 99) ? '99+' : data.total
				
					}
				}
			})
	},
	methods: {
		// Load More
		loadMore () {
			this.pagination.activePage += 1
			this.getDocumentList()
			.then( data => {
				data.items.forEach((item) => {
					this.document.data.push(item)
				})
				this.renderData = false

				if(this.pagination.itemLoaded >= data.total) this.pagination.ending = true
			})
		},
		// Filter
		applyAdvanceFilter (params) {
			this.document.data = []
			let advanceSearchComp = this.$refs.advanceSearch
			advanceSearchComp.loading = true
			
			this.document.filters = params.filters
			this.document.sorts = params.sorts
			this.getDocumentList()
				.then( data => {
					data.items.forEach((item) => {
						this.document.data.push(item)
					})
					this.renderData = false
					advanceSearchComp.refreshFilterBadges()
				}).catch( err => {
					console.log('Failed fetch document data with filter')
					console.log(err)
				}).finally(() => {
					advanceSearchComp.loading = false
				})
		},
		getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				if (res) {
					res.result.forEach((item) => {
						let rowData = {
							id: item.category_id,
							name: item.category_name,
							label: item.category_name
						}
						items.push(rowData)
					})
				}
				
                this.categoryOptions = items
			})
		},
		getDocumentList () {
			this.loading = true
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocument',
						pagination: {
							limit: this.pagination.itemsPerPage,
							offset: (this.pagination.activePage-1)*this.pagination.itemsPerPage
						},
						filter: this.document.filters,
						sort: this.document.sorts
					}
				).then( res => {
					let items = []
					
					let total = 0
					if (res) {
						total = (res.total_count)?res.total_count:res.result.length

						res.result.forEach((item) => {
							items.push(item)
						})
						this.pagination.itemLoaded += res.result.length
					}
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				})
			})
		},
		// Navigation and Functions
		closeArchiveDocumentModal (refreshState) {
			this.modalArchiveDocument.isShow = false
			this.renderData = true
			this.getDocumentList()
				.then( data => {
					this.document.data = data.items
					this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
					this.renderData = false
				})
		},
	}
}
</script>

<style scoped lang="scss">
#app {
	background-color: #f5f5f5;
}
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>